import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { motion, AnimatePresence } from 'framer-motion';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import emailjs from '@emailjs/browser';

const theme = {
  background: '#ffffff',
  text: '#333333',
  primary: '#000000',
  secondary: '#666666',
  accent: '#000000'
};

const AppContainer = styled.div`
  scroll-padding-top: 120px;
  min-height: 100vh;
  background: ${props => props.theme.background};
  @media (max-width: 768px) {
    padding: 10em 1rem;
  }
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.98);
  z-index: 1000;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const Logo = styled.div`
  width: 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-left: auto;
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-left: 0;
  }
`;

const NavLink = styled.a`
  color: ${props => props.theme.primary};
  text-decoration: none;
  text-transform: lowercase;
  font-size: 0.9rem;
  letter-spacing: 1px;
  &:hover {
    opacity: 0.7;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-left: 2rem;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 1rem;
    flex-direction: row;
    justify-content: center;
  }

  a {
    color: ${props => props.theme.primary};
    font-size: 1rem;
    &:hover {
      opacity: 0.7;
    }
  }
`;

const AboutSection = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  background: linear-gradient(135deg, #ffffff 0%, #f0f7ff 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 15% 85%, rgba(230, 244, 255, 0.8) 0%, transparent 50%),
      radial-gradient(circle at 85% 15%, rgba(255, 241, 242, 0.8) 0%, transparent 50%);
    pointer-events: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 1rem 40px;
  }
`;

const HeroHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  position: relative;
  text-align: center;

  &:first-child {
    background: #fff;
  }
  
  &:last-child {
    background: #fff;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.1);
      z-index: 1;
    }
  }
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const HeroImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 300px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  z-index: 2;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: visible;
  }
  @media (max-width: 768px) {
    position: static;
    transform: none;
    width: 80%;
    height: auto;
    overflow: visible;
  }
`;

const HeroTitle = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: ${props => props.theme.primary};
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.secondary};
  max-width: 300px;
  text-align: center;
  line-height: 1.6;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ProjectImage = styled.div`
  width: 100%;
  height: auto;
  background: #f8f9fa;
  overflow: hidden;
  border-radius: 12px;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
    &:hover img {
    transform: scale(1.03);
  }
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const ExpertiseSection = styled.div`
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 2rem;
`;

const SkillsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
`;

const SkillTag = styled.span`
  padding: 0.8rem 1.5rem;
  background: #f8f9fa;
  border-radius: 30px;
  font-size: 1rem;
  color: ${props => props.theme.secondary};
  transition: all 0.3s ease;
  border: 1px solid rgba(0,0,0,0.05);
  
  &:hover {
    background: ${props => props.theme.primary};
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }
`;

const WorkSection = styled.section`
  padding: 80px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #f5f7fa;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  max-width: 1400px;
  margin: 4rem auto;
  padding: 0 2rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }
`;

const ProjectInfo = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    text-align: left;
    width: 100%;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: ${props => props.theme.primary};
    position: relative;
    display: inline-block;
    
    &:after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 3px;
      background: ${props => props.theme.primary};
      border-radius: 2px;
    }
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;

    h3 {
      font-size: 1.8rem;
    }
  }
`;

const ProjectCard = styled.div`
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 5px 30px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  height: 100%;

  &:first-child {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    gap: 0;
    background: white;
    align-items: center;
    padding: 2rem;

    ${ProjectImage} {
      height: auto;
      border-radius: 12px;
      margin: 0;
      display: flex;
      align-items: center;
      
      img {
        object-fit: contain;
        border-radius: 12px;
        box-shadow: 0 10px 30px rgba(0,0,0,0.1);
      }
    }

    ${ProjectInfo} {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
      padding: 1.5rem;
      
      ${ProjectImage} {
        margin: 1rem;
        
        img {
          object-fit: contain;
        }
      }
      
      ${ProjectInfo} {
        padding: 0;
      }
    }
  }

  &:not(:first-child) {
    ${ProjectImage} {
      aspect-ratio: 16/10;
      margin: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    
    ${ProjectInfo} {
      padding: 1.5rem;
    }
  }

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 40px rgba(0,0,0,0.08);
  }

  @media (max-width: 1200px) {
    max-width: 800px;
    margin: 0 auto;
  }
`;

const TechStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: auto;
  padding-top: 2rem;
  border-top: 1px solid rgba(0,0,0,0.05);
`;

const TechItem = styled.span`
  padding: 0.8rem 1.5rem;
  background: #f8f9fa;
  border-radius: 30px;
  font-size: 0.95rem;
  color: ${props => props.theme.secondary};
  transition: all 0.3s ease;
  font-weight: 500;

  &:hover {
    background: ${props => props.theme.primary};
    color: white;
  }
`;

const Section = styled.section`
  padding: 80px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${props => props.id === 'expertise' ? '#f8f9fa' : props.id === 'contact' ? '#f1f3f5' : '#fff'};
`;

const GetInTouchSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  background: #f7f9fc;
  @media (max-width: 768px) {
    padding: 40px 1rem;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    flex-direction: column;
  }
`;

const ContactContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContactInfo = styled.div`
  margin-bottom: 2rem;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    color: ${props => props.theme.secondary};
    margin-bottom: 2rem;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  
  svg {
    color: ${props => props.theme.primary};
  }
  
  a {
    color: ${props => props.theme.secondary};
    &:hover {
      color: ${props => props.theme.primary};
    }
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
  margin-left: 3rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-left: 0;
  }

  input, textarea {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: inherit;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    &:focus {
      outline: none;
      border-color: ${props => props.theme.primary};
    }
  }

  button {
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background: ${props => props.theme.primary};
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover {
      background: ${props => props.theme.accent};
    }
  }
`;

const Button = styled.button`
  background: ${props => props.theme.primary};
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const Footer = styled.footer`
  background-color: #f8f9fa;
  padding: 1rem 0;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
`;

const Copyright = styled.p`
  margin: 0;
  color: #6c757d;
  font-size: 0.9rem;
`;

const SectionTitle = styled.h2`
  font-size: 1rem;
  color: ${props => props.theme.secondary};
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2rem;
`;

const ImageModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
`;

const CarouselContainer = styled(animated.div)`
  position: relative;
  width: 90vw;
  max-width: 1200px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
    border-radius: 15px;
    transition: transform 0.3s ease;
  }
`;

const CarouselImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 15px;
`;

const CarouselArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  z-index: 10;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 4px 12px rgba(0,0,0,0.3);
  }

  &:focus {
    outline: none;
  }

  ${props => props.left ? 'left: 10px;' : 'right: 10px;'}

  svg {
    width: 24px;
    height: 24px;
    fill: white;
  }
`;

const ImageCounter = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
`;

const ImageDots = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => props.active ? 'white' : 'rgba(255, 255, 255, 0.3)'};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
`;

const ProjectCarousel = ({ images, isOpen, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [{ x }, api] = useSpring(() => ({ x: 0 }));
  const bind = useDrag(({ down, movement: [mx], cancel }) => {
    if (down && Math.abs(mx) > window.innerWidth * 0.25) {
      cancel();
      if (mx > 0 && currentIndex > 0) {
        setCurrentIndex(i => i - 1);
      } else if (mx < 0 && currentIndex < images.length - 1) {
        setCurrentIndex(i => i + 1);
      }
    }
    api.start({ x: down ? mx : 0, immediate: down });
  });

  const handleNext = (e) => {
    e.stopPropagation();
    if (currentIndex < images.length - 1) {
      setCurrentIndex(i => i + 1);
    }
  };

  const handlePrev = (e) => {
    e.stopPropagation();
    if (currentIndex > 0) {
      setCurrentIndex(i => i - 1);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <ImageModal
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <CarouselContainer {...bind()} style={{ x }}>
            <CarouselImage
              key={currentIndex}
              src={images[currentIndex]}
              alt={`Project image ${currentIndex + 1}`}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 30,
                mass: 0.8
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
            />
            <CarouselArrow
              className="prev"
              left
              onClick={handlePrev}
            >
              ←
            </CarouselArrow>
            <CarouselArrow
              className="next"
              onClick={handleNext}
            >
              →
            </CarouselArrow>
            <ImageCounter>
              {currentIndex + 1} / {images.length}
            </ImageCounter>
            <ImageDots>
              {images.map((_, index) => (
                <Dot
                  key={index}
                  active={index === currentIndex}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentIndex(index);
                  }}
                />
              ))}
            </ImageDots>
          </CarouselContainer>
        </ImageModal>
      )}
    </AnimatePresence>
  );
};

const projectImages = {
  leafy: ['/leafynyexample1.png', '/leafynyexample2.png', '/leafynyexample3.png', '/leafynyexample4.png', '/leafynyexample5.png'],
  casey: ['/caseyexample1.png', '/caseyexample2.png', '/caseyexample3.png', '/caseyexample5.png', '/caseyexample4.png'],
  taletrail: ['/taletrailexample2.png', '/taletrailexample3.png', '/taletrailexample4.png', '/taletrailexample5.png']
};

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleProjectClick = (project) => {
    setCurrentProject(project);
    setModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      await emailjs.send(
        'service_6xc45xb',
        'template_cqt6rqa',
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
          to_email: 'connect@mikehaye.com'
        },
        'AW0ezpunhl4ti3Hef'
      );
    
      alert('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <Header>
          <Logo>
            <span style={{ fontSize: '1.2rem', fontWeight: '600' }}>MH</span>
          </Logo>
          <Nav>
            <NavLink href="#about">about</NavLink>
            <NavLink href="#expertise">expertise</NavLink>
            <NavLink href="#portfolio">portfolio</NavLink>
            <NavLink href="#contact">contact</NavLink>
          </Nav>
          <SocialIcons>
            <a href="https://github.com/mhaye20" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <a href="https://linkedin.com/in/michael-haye" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </SocialIcons>
        </Header>

        <AboutSection id="about">
          <HeroHalf>
            <HeroTitle>Full Stack</HeroTitle>
            <HeroSubtitle>
              Developer with 8 years in healthcare and business services, known for hands-on leadership and making tech work seamlessly for business.
            </HeroSubtitle>
          </HeroHalf>
          <HeroImage>
            <img src="/mikehaye.png" alt="Mike Haye" />
          </HeroImage>
          <HeroHalf>
            <HeroTitle>&lt;Developer&gt;</HeroTitle>
            <HeroSubtitle>
            Building secure, scalable applications with modern frameworks and cloud technologies to solve real business challenges.
            </HeroSubtitle>
          </HeroHalf>
        </AboutSection>

        <Section id="expertise">
          <SectionTitle>TECHNICAL EXPERTISE</SectionTitle>
          <ExpertiseSection>
            <SkillsList>
              <SkillTag>Bootstrap</SkillTag>
              <SkillTag>Express</SkillTag>
              <SkillTag>Firebase</SkillTag>
              <SkillTag>Git</SkillTag>
              <SkillTag>Google Analytics</SkillTag>
              <SkillTag>Google Cloud Platform</SkillTag>
              <SkillTag>Google Workspace</SkillTag>
              <SkillTag>HIPAA</SkillTag>
              <SkillTag>IAM</SkillTag>
              <SkillTag>JavaScript</SkillTag>
              <SkillTag>Node</SkillTag>
              <SkillTag>Nuxt</SkillTag>
              <SkillTag>Pinia</SkillTag>
              <SkillTag>PostgreSQL</SkillTag>
              <SkillTag>Prisma ORM</SkillTag>
              <SkillTag>React</SkillTag>
              <SkillTag>Redis</SkillTag>
              <SkillTag>SEO</SkillTag>
              <SkillTag>SQL</SkillTag>
              <SkillTag>Supabase</SkillTag>
              <SkillTag>Tailwind</SkillTag>
              <SkillTag>TypeScript</SkillTag>
              <SkillTag>Vertex AI</SkillTag>
              <SkillTag>Vue</SkillTag>
              <SkillTag>Vuetify</SkillTag>
              <SkillTag>Webpack</SkillTag>
            </SkillsList>
          </ExpertiseSection>
        </Section>

        <WorkSection id="portfolio">
          <SectionTitle>FEATURED PROJECTS</SectionTitle>
          <ProjectGrid>
            <ProjectCard data-aos="fade-up">
              <ProjectImage onClick={() => handleProjectClick('leafy')}>
                <img src="/leafyny.png" alt="LeafyNY" />
              </ProjectImage>
              <ProjectInfo>
                <div>
                  <h3>LeafyNY</h3>
                  <p>A comprehensive platform designed to help cannabis businesses in New York State manage their operations, ensure compliance, and streamline their business processes. The platform combines real-time regulatory updates, automated tax management, and AI-powered business tools.</p>
                  <TechStack>
                    <TechItem>Nuxt 3</TechItem>
                    <TechItem>Vue 3</TechItem>
                    <TechItem>Node.js</TechItem>
                    <TechItem>TypeScript</TechItem>
                    <TechItem>Tailwind</TechItem>
                    <TechItem>Prisma</TechItem>
                    <TechItem>PostgreSQL</TechItem>
                    <TechItem>Redis</TechItem>
                    <TechItem>Supabase</TechItem>
                    <TechItem>Vertex AI</TechItem>
                  </TechStack>
                </div>
              </ProjectInfo>
            </ProjectCard>
            <ProjectCard data-aos="fade-up" data-aos-delay="100">
              <ProjectImage onClick={() => handleProjectClick('casey')}>
                <img src="/casey3.png" alt="CaseyPro" />
              </ProjectImage>
              <ProjectInfo>
                <div>
                  <h3>Casey</h3>
                  <p>A sophisticated project management tool designed for healthcare professionals, featuring real-time collaboration, HIPAA compliance, and advanced analytics.</p>
                  <TechStack>
                    <TechItem>Vue</TechItem>
                    <TechItem>Vuetify</TechItem>
                    <TechItem>Node.js</TechItem>
                    <TechItem>Firebase</TechItem>
                    <TechItem>Chart.js</TechItem>
                    <TechItem>Socket.io</TechItem>
                    <TechItem>Vite</TechItem>
                    <TechItem>Webpack</TechItem>
                    <TechItem>DevExtreme</TechItem>
                  </TechStack>
                </div>
              </ProjectInfo>
            </ProjectCard>
            <ProjectCard data-aos="fade-up" data-aos-delay="200">
              <ProjectImage onClick={() => handleProjectClick('taletrail')}>
                <img src="/taletrailexample2.png" alt="TaleTrail" />
              </ProjectImage>
              <ProjectInfo>
                <div>
                  <h3>TaleTrail</h3>
                  <p>A mobile app that transforms everyday walks into interactive storytelling adventures, using AI to generate location-based narratives.</p>
                  <TechStack>
                    <TechItem>React Native</TechItem>
                    <TechItem>Expo</TechItem>
                    <TechItem>TypeScript</TechItem>
                    <TechItem>NativeWind</TechItem>
                    <TechItem>Supabase</TechItem>
                    <TechItem>Google Cloud</TechItem>
                    <TechItem>Vercel</TechItem>
                    <TechItem>Vertex AI</TechItem>
                  </TechStack>
                </div>
              </ProjectInfo>
            </ProjectCard>
          </ProjectGrid>
          {modalOpen && currentProject && (
            <ProjectCarousel
              images={projectImages[currentProject]}
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
            />
          )}
        </WorkSection>

        <GetInTouchSection id="contact">
          <SectionTitle>GET IN TOUCH</SectionTitle>
          <ContactContainer>
            <ContactInfo data-aos="fade-up">
              <h3>Let's Connect</h3>
              <p>Have a project in mind? Let's discuss how we can work together.</p>
              <ContactDetails>
                <ContactItem>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <a href="mailto:connect@mikehaye.com">connect@mikehaye.com</a>
                </ContactItem>
                <ContactItem>
                  <FontAwesomeIcon icon={faLinkedin} />
                  <a href="https://linkedin.com/in/michael-haye" target="_blank" rel="noopener noreferrer">linkedin.com/in/michael-haye</a>
                </ContactItem>
                <ContactItem>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>Brooklyn, NY</span>
                </ContactItem>
              </ContactDetails>
            </ContactInfo>
            <ContactForm data-aos="fade-up" data-aos-delay="200" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
              <button type="submit">Send Message</button>
            </ContactForm>
          </ContactContainer>
        </GetInTouchSection>

        <Footer>
          <Copyright>
            &copy; {new Date().getFullYear()} Mike Haye
          </Copyright>
        </Footer>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
